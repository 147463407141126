<template>
<div>
	<div class="header text-center">
		<b-container>
			<div class="display-2 mb-3">
				Farewell, friend.
			</div>
			<div class="lead m-0 text-muted">
				Thanks for giving Amino a try. Please help us understand why you decided to uninstall.
			</div>
		</b-container>
	</div>
	<b-container class="d-flex justify-content-center align-items-center">
		<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdZYjUg-5zRBMnAFpCvap6BR-CYFpKSAxfrZFHDDJbgomueUg/viewform?embedded=true" width="640" height="1240" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
	</b-container>
	<div class="header text-center">
		<b-container>
			<div class="h1 mb-4">
				Uninstall by accident?
			</div>
			<div class="lead m-0 text-muted">
				<b-button variant="primary" rel="noopener" :href="getExtensionLink('chrome')" target="_blank">
					Add To Chrome
					<span class="material-icons-outlined">chevron_right</span>
				</b-button>
			</div>
		</b-container>
	</div>
</div>
</template>

<script>
export default {
	name: 'Goodbye',
	async mounted() {
		try {
			await this.$store.dispatch('auth/logout');
		} catch (e) {
			console.log('LOGOUT ISSUE:', e);
		}
		this.loadGoogleOneTap();
	}
}
</script>

<style lang="scss" scoped>
.header {
	padding: 5rem 0;
}
</style>
