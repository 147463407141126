<template>
<div class="d-flex flex-grow-1 body">
	<div class="container position-relative">
		<div class="text-center mb-5">
			<b-container class="blurb position-relative">
				<div class="display-1 mb-3">
					PX to REM Converter
				</div>
				<div class="lead text-muted">
					A handy tool for easy PX to REM conversions (and vise versa).
				</div>
			</b-container>
		</div>
		<div class="converter flex-grow-1">
			<div class="mb-5">
				<div class="d-flex align-items-center mb-3">
					<div class="text-white font-weight-bold lead">
						Base pixel font size
					</div>
					<div class="text-muted small ml-3">
						Default is 16px
					</div>
				</div>
				<div>
					<input v-model="converter.base" class="input" type="number" placeholder="Default: 16px" />
				</div>
			</div>

			<div class="d-flex align-items-end">
				<div>
					<div class="text-white font-weight-bold lead mb-3">
						px value
					</div>
					<div>
						<div v-if="!rtpMode">
							<input v-model="converter.px" class="input" type="number" />
						</div>
						<div class="position-relative" v-else>
							<div class="copy" v-b-tooltip.left.hover :title="'Copy to Clipboard'" @click.prevent="$copyText(px); makeToast('Copied to clipboard');">
								<span class="material-icons-outlined">copy</span>
							</div>
							<input v-model="px" class="input" type="text" disabled />
						</div>
					</div>
				</div>
				<div style="height: 56px;" class="d-flex align-items-center mx-3">
					<div class="arrow-button d-flex align-items-center justify-content-center" @click.prevent="rtpMode = !rtpMode">
						<span class="material-icons-outlined md-light">
							swap_horiz
						</span>
					</div>
				</div>
				<div>
					<div class="text-white font-weight-bold lead mb-3">
						rem value
					</div>
					<div>
						<div v-if="rtpMode">
							<input v-model="converter.rem" class="input" type="number" />
						</div>
						<div class="position-relative" v-else>
							<div class="copy" v-b-tooltip.left.hover :title="'Copy to Clipboard'" @click.prevent="$copyText(rem); makeToast('Copied to clipboard');">
								<span class="material-icons-outlined">copy</span>
							</div>
							<input v-model="rem" class="input" type="text" disabled />
						</div>
					</div>
				</div>
			</div>
			<!-- <button v-on:click="isHidden = true">Hide the text below</button>
			<button v-on:click="isHidden = !isHidden">Toggle hide and show</button>
			<div v-if="!isHidden">
				<pre v-for="code in prettyCSS" :key="code.selector" class="text-white"><code>{{code.selector}} {{code.styles}}</code></pre>
				<prism v-for="code in prettyCSS" :key="code.selector" ref="prism" language="css" class="allow-select" :code="code.selector|code.styles" :plugins="pluginConfigs"></prism>
			</div> -->
		</div>
		<Ad class="ad w-100" type="carbon" :key="$route.path" v-slot="{ ad }" show>
			<a class="helper rounded d-block" :href="`${apiURL}/cdn/r/${base64Encode(ad.statlink)}`" target="_self" v-if="['custom', 'default'].includes(ad.rendering)">
				<div>
					<div class="d-inline-block logo-wrapper rounded p-3" :style="{ 'background-color': ad.backgroundColor}">
						<img :src="`${apiURL}/cdn/img/${base64Encode(ad.logo)}`" title="Sponsored By Carbon" class="d-block" style="max-width: 125px;" />
					</div>
				</div>
				<div class="w-100 d-flex flex-column">
					<div class="description">
						{{ ad.description }}
					</div>
					<div class="meta text-muted">
						Sponsored by Carbon
					</div>
				</div>
			</a>
			<a class="helper smaller rounded d-flex align-items-center" style="gap: 1rem" :href="`${apiURL}/cdn/r/${base64Encode(ad.statlink)}`" target="_blank" v-if="ad.rendering == 'carbon'">
				<div class="logo-wrapper">
					<img :src="`${apiURL}/cdn/img/${base64Encode(ad.smallImage)}`" title="Sponsored By Carbon" class="d-block rounded" style="max-width: 130px;" />
				</div>
				<div>
					<div class="description">
						{{ ad.description }}
					</div>
					<div class="meta text-muted">
						Sponsored by Carbon
					</div>
				</div>
			</a>
		</Ad>
	</div>
</div>
</template>

<script>
const Ad = () => import('@/components/Ad');
export default {
	name: 'RemConverter',
	components: {
		Ad,
	},
	data() {
		return {
			isHidden: false,
			rtpMode: false,
			converter: {
				base: 16,
				px: 16,
				rem: 1
			}
		}
	},
	computed: {
		prettyCSS() {
			return [{
				selector: ".px-example",
				styles: {
					"font-size": this.px
				}
			}, {
				selector: ".rem-example",
				styles: {
					"font-size": this.rem
				}
			}]
		},
		px() {
			return `${this.rtp(this.converter.rem)}px`;
		},
		rem() {
			return `${this.ptr(this.converter.px)}rem`;
		}
	},
	methods: {
		ptr(px) {
			return this.converter.rem = (1 / this.converter.base * px);
		},
		rtp(rem) {
			return this.converter.px = rem / (1 / this.converter.base);
		}
	},

}
</script>

<style lang="scss" scoped>
.body {
	padding: 4rem 0;
}
.display-1 {
	font-size: 3.25rem;
}
.converter {
	z-index: 10;
	max-width: 480px;
	margin: 4rem auto 0;
	padding: 3rem;
	border-radius: var(--property-border-radius);
	background-color: var(--color-700);
	border: 1px solid var(--color-500);

	input {
		background-color: var(--color-500) !important;
		font-size: 1.25rem !important;
	}

	.copy {
		position: absolute;
		top: 50%;
		right: 1rem;
		cursor: pointer;
		color: var(--color-100);
		transform: translateY(-50%);
		&:hover {
			color: var(--color-white);
		}

		.material-icons-outlined {
			font-size: 1.25rem !important;
		}
	}
}

.row {
	margin: 0.625rem 0;
}

article {
	font-size: 1.25em;

	header {
		width: calc(100% + 2rem);
		margin-bottom: 2.5rem;
		padding: 2rem;
		background-color: var(--color-700);
		transform: translateX(-2rem);
		border-radius: var(--property-border-radius);
		.sharing-links {
			position: absolute;
			right: 3rem;
			bottom: 0;
			transform: translateY(50%);
		}
	}

	p {
		opacity: 0.75;
	}
}

.lead {
	color: var(--color-100);
}

.row:after {
	content: '';
	display: table;
	clear: both;
}

.row .w12,
.row .w6 {
	position: relative;
	width: 50%;
	float: left;
	padding: 0 0.3125rem;
}

.row .w12 {
	width: 100%;
}

input[type="text"],
input[type="number"] {
	display: block;
	width: 100%;
	color: var(--color-white);
	background-color: var(--color-300);
	border-radius: var(--property-border-radius);
	font-weight: var(--font-weight-bold);
	font-size: 1rem;
	padding: 1rem;
	border: 0;
}

input[type="text"][readonly] {
	background-color: var(--color-100);
}

.table-column {
	width: 100%;
	max-width: 280px;
	margin-left: 6rem;
}

.ad {
	max-width: 480px;
	margin: 2rem auto 0;
	padding: 1.5rem;
	background-color: var(--color-500);
	transition: var(--property-transition);
	&:hover {
		transform: scale(1.025);
	}
	.logo-wrapper {
		margin-right: 0.75rem;
		img {
			min-width: 3rem;
		}
	}
	.ad-logo {
		box-shadow: inset rgba(0, 0, 0, 0.05) 0 0 0 1px;
		padding: 0.5rem;
		border-radius: 4px;
		img {
			width: 3rem;
		}
	}
	.description {
		font-size: 1.25rem;
		line-height: 1.4;
	}
	.meta {
		font-size: 0.75rem;
		line-height: 1.4;
		margin-top: 0.5rem;
	}
	a {
		color: inherit;
		text-decoration: none;
		color: var(--color-text);
	}
	// @media only screen and (max-width: 900px) {
	// 	max-width: 240px;
	// }
}
</style>

<style lang="scss">
.table {
	color: var(--color-white) !important;
	font-size: 0.8125rem;
	border: 0 !important;
	border-radius: var(--property-border-radius) !important;
	thead {
		border-top-left-radius: var(--property-border-radius);
		border-top-right-radius: var(--property-border-radius);
		th {

			border: 0 !important;
		}
	}
	td,
	th {
		padding: 0.75rem;
		vertical-align: top;
		border-top: 0 !important;
	}
	tr {
		border-radius: var(--property-border-radius);
		&:hover {
			background-color: var(--color-700) !important;
		}
	}
}

.sharing-links {
	.item {
		&:not(:last-of-type) {
			margin-right: 1rem;
		}
		a {
			width: 2.5rem;
			height: 2.5rem;
			background-color: var(--color-300);
			box-shadow: var(--property-box-shadow);
			border-radius: 5rem;
		}
	}

	.icon {
		width: 1.25rem;
		fill: var(--color-white);
	}
}
</style>
