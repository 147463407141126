<template>
<div class="d-flex flex-column h-100">
	<NavbarMain></NavbarMain>
	<router-view></router-view>
	<FooterMain></FooterMain>
</div>
</template>

<script>
const NavbarMain = () => import('@/components/NavbarMain');
const FooterMain = () => import('@/components/FooterMain');
export default {
	name: 'Tools',
	components: {
		NavbarMain,
		FooterMain
	}
}
</script>

<style lang="scss">

</style>
